<template>
  <v-container class="pa-12">
    <v-row>
      <v-col cols="12" md="4">
        <copy title="api_key" :text="settings.apiKey" />
      </v-col>
      <v-col cols="12" md="4">
        <copy title="secret_key" :text="settings.secretKey" />
      </v-col>
      <v-col cols="12" md="4">
        <copy title="ipn_url" :text="settings.ipnUrl" />
      </v-col>
      <v-col cols="12">
        <v-card elevation="2" rounded="lg" class="pa-6">
          <v-data-table
            :headers="paymentMethodsListHeaders"
            :items="paymentMethodsListItems"
            disable-sort
            hide-default-footer
            :server-items-length="paymentMethodsListItems.length"
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-toolbar-title>
                  {{ $t('tables.titles.payment_methods') }}
                </v-toolbar-title>
                <v-spacer />
              </v-toolbar>
            </template>

            <template v-slot:item.methodCatId="{ item }">
              {{
                $t(
                  `payment_categories_2.${choose_payment_category(
                    item.methodCatId
                  )}`
                )
              }}
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import Copy from '@/components/Copy'
import validate from '@/mixins/validate'
import choose from '@/mixins/choose'
import requests from '@/mixins/requests'
export default {
  components: { Copy },
  metaInfo: function() {
    return {
      title: `${this.$t('pages.titles.api_settings')}`,
      titleTemplate: `%s | PAYMAPI ${this.$t('app')}`
    }
  },
  name: 'Profile',
  mixins: [validate, choose, requests],
  data: () => ({
    ipListTable: {
      headers: [
        { text: 'IP', value: 'ip' },
        { text: 'Actions', value: 'actions' }
      ],
      items: [
        {
          ipId: '29',
          ip: '127.0.0.1'
        }
      ]
    },
    paymentMethodsListItems: [],
    ipForm: {
      ipId: null,
      ip: null
    },
    settings: {
      apiKey: null,
      clientName: null,
      ipnUrl: null,
      logoUrl: null,
      secretKey: null
    },
    dialogs: {
      save: false,
      delete: false
    },
    updates: {
      ipn: false
    }
  }),
  mounted() {
    this.readSettings()
    this.paymentMethodList()
  },
  methods: {
    async readSettings() {
      const data = await this._readSettings()
      this.settings = { ...data }
    },
    async paymentMethodList() {
      const data = await this._paymentMethodList()
      console.log(data)
      this.paymentMethodsListItems = [...data]
    },
    async saveItem() {
      if (this.$refs.form.validate()) {
        console.log(this.ipForm)
        this.resetForm({
          dialog: 'save',
          ref: this.$refs.form,
          form: this.ipForm
        })
      }
    },
    async deleteItem() {
      console.log(this.ipForm)
      this.resetForm({
        dialog: 'delete',
        ref: this.$refs.form,
        form: this.ipForm
      })
    },
    showDialog({ dialog, item = null }) {
      this.dialogs[dialog] = true
      item ? (this.ipForm = { ...item }) : null
    },
    resetForm({ dialog, ref = null, form = null }) {
      Object.keys(form).map(key => (form[key] = null))
      ref.resetValidation()
      this.dialogs[dialog] = false
    }
  },
  computed: {
    paymentMethodsListHeaders: function() {
      let headers = []
      ;['methodName', 'methodCatId', 'paymentMethodCode'].map(item => {
        headers.push({
          value: item,
          text: this.$t(`tables.headers.payment_methods.${item}`)
        })
      })
      return headers
    }
  }
}
</script>

<style>
.h-full {
  height: 100%;
}
</style>
