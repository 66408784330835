<template>
  <v-card elevation="2" rounded="lg" class="h-full">
    <v-card-title>
      <span>{{ $t(title) }}</span>
      <v-spacer />
      <v-tooltip bottom>
        <template v-slot:activator="{ attrs, on }">
          <v-btn v-bind="attrs" v-on="on" icon @click="copyToClipboard(title)">
            <v-icon color="secondary">
              mdi-content-copy
            </v-icon>
          </v-btn>
        </template>
        <span>
          {{ $t('common.copy') }}
        </span>
      </v-tooltip>
    </v-card-title>
    <v-divider />
    <v-card-text>
      {{ text }}
    </v-card-text>
    <input :id="title" type="hidden" :value="text" />
    <v-snackbar v-model="coppied" timeout="3000" color="success">
      {{ $t('common.copy_success') }}
    </v-snackbar>
  </v-card>
</template>

<script>
export default {
  name: 'Copy',
  props: ['title', 'text'],
  data: () => ({
    coppied: false
  }),
  methods: {
    copyToClipboard(title) {
      const testingCodeToCopy = document.querySelector(`#${title}`)
      testingCodeToCopy.setAttribute('type', 'text')
      testingCodeToCopy.select()

      try {
        document.execCommand('copy')
        this.coppied = true
      } catch (err) {
        alert('Oops, unable to copy')
      }

      testingCodeToCopy.setAttribute('type', 'hidden')
      window.getSelection().removeAllRanges()
    }
  }
}
</script>

<style lang="scss" scoped>
.h-full {
  height: 100%;
}
</style>
